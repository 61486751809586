<template>
  <div>
    <bigEnterprise :roleType="'platform'" />
  </div>
</template>
  
<script>
import bigEnterprise from "@/views/supplier/bigCustomerManage/bigEnterpriseManage/bigEnterpriseManage.vue";


export default {
  data() {
    return {}
  },
  components: {
    bigEnterprise
  },
};
</script>